import Interop from "../interfaces/Interop";
import { ModuleType } from "../enums/ModuleType";
import { ApplicationColor } from "../models/ApplicationColor";
import { ChatPageNavigation } from "../models/ChatPageNavigation";
import { ClientCredential } from "../models/ClientCredential";
import { ChannelDataModel } from "../models/ChannelDataModel";
import { ChatPageType } from "../enums/ChatPageType";

export default class WebInterop implements Interop {
    public responseHandlers: Map<string, (value: any) => void> = new Map();
    private pwaEventOrigin = process.env.REACT_APP_PWA_ORIGIN || "";
    private vueEventOrigin = process.env.REACT_APP_VUE_ORIGIN || "";
    
    constructor() {
        this.initMessageListener();
    }

    public initMessageListener() {
        window.addEventListener("message", this.handleMessage);
    }

    FinishedLoading(): void {
        console.log("Finished Loading");
    }
    async GetLanguage(): Promise<string> {
        const languageCode = await this.sendMessageAndWaitForResponse("GetLanguage", String);
        return Promise.resolve(languageCode);
    }
    ToogleMenu(): void {
        console.log("Menu Toggled!");
    }

    SlidingGesture(enable: boolean): void {
        console.log("Sliding Gesture: " + enable);
    }

    GetFontSize(): Promise<Number> {
        return Promise.resolve(100);
    }

    async GetCredentials(): Promise<ClientCredential> {
        const response = await this.sendMessageAndWaitForResponse("GetCredentials", JSON.parse);
        const { UserId, UserToken, TeamId, EventId, EventName } = response;
        return new ClientCredential(UserId, UserToken, TeamId, Number.parseInt(EventId), EventName);
    }

    async GetColors(): Promise<ApplicationColor> {
        const response = await this.sendMessageAndWaitForResponse("GetColors", JSON.parse);
        const { TopNavBackgroundColor, TopNavForegroundColor } = response;
        return new ApplicationColor(TopNavBackgroundColor, TopNavForegroundColor);
    }

    async GetPageType(): Promise<ChatPageNavigation> {
        const pageTypeResponse = await this.sendMessageAndWaitForResponse("GetPageType", JSON.parse);
        const { ChatPageType, EntityType, EntityId, ChannelHashId, PushMessageId } = pageTypeResponse;
        return new ChatPageNavigation(ChatPageType, EntityType, EntityId, ChannelHashId, PushMessageId);
    }

   
    SendNewMessageUpdate(channelData: ChannelDataModel): void {
        this.sendMessage("SendNewMessageUpdate", JSON.stringify(channelData));
    }

    NavigationHandle(enable: boolean): void {
        console.log("Navigation Handle: " + enable);
    }

    PushHandle(ReceiverUserId: string): void {
        console.log("PushHandle", ReceiverUserId);
    }

    public sendMessage(type: string, channelData?: string) {
        const message: { type: string; channelData?: string } = { type };

        if (channelData) {
            // Serialize the channelData object to a string if it's present
            message.channelData = JSON.stringify(channelData);
        }

        window.parent.postMessage(message, this.pwaEventOrigin+"/");
        window.parent.postMessage(message, this.vueEventOrigin+"/");
    }
    public sendMessageAndWaitForResponse<T>(type: string, transform: (data: any) => T): Promise<T> {
        return new Promise((resolve, reject) => {
            const handler = (data: any) => {
                try {
                    resolve(transform(data));
                } catch (error) {
                    reject(error);
                } finally {
                    this.responseHandlers.delete(type);
                }
            };
            this.responseHandlers.set(type, handler);
            this.sendMessage(type);
        });
    }

    public handleMessage = (event: MessageEvent) => {
        if (event.origin === this.pwaEventOrigin || event.origin === this.vueEventOrigin) {
            const { type, data } = event.data;
            const handler = this.responseHandlers.get(type);
            if (handler) {
                handler(data);
            }
        }
    };

    GetPlatformType(): Promise<string> {
        return Promise.resolve("BlazorPWA");
    }
}


