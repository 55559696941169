import React from 'react';
import { useChatContext } from 'stream-chat-react';

import { HamburgerIcon } from '../../assets';

import type { StreamChatGenerics } from '../../types';

type Props = {
  onCreateChannel?: () => void;
  platformType:string,
  languageCode:any,
  eventName:string
};

const MessagingChannelListHeader = React.memo((props: Props) => {
  const { onCreateChannel,platformType, languageCode, eventName } = props;

  const { client } = useChatContext<StreamChatGenerics>();
  const headertext = client.user?.role === 'moderator' ? eventName :  languageCode['Messaging']; 

  return (
      <div className='messaging__channel-list__header'>
        <button
          className= 
          {(platformType==="Mobile")?
            `messaging__channel-list__header__button_mobile`:'messaging__channel-list__header__button'}
          onClick={onCreateChannel}>
          <HamburgerIcon />
        </button>
        {/* <Avatar image={image} name={name} size={40} /> */}
        <div className={`messaging__channel-list__header__name`}>{headertext}</div>
      </div>
  );
});

export default React.memo(MessagingChannelListHeader);
