import React, { useEffect, useState } from 'react';
import { Avatar, MessageSimple, useChannelStateContext, useChatContext, useMessageContext } from 'stream-chat-react';
import './CustomMessageRenderer.css';
import { AvatarChannelView } from '../components';
import { ChatPageType } from '../enums/ChatPageType';
import CustomReactionSelectors from '../../src/components/CustomReactionSelectors/CustomReactionSelectors'

const getNameInitials = (username: string): string => {
    let initials = '';
    if (username && username.trim() !== '') {
        const tempUserName = username.trim().split(' ');
        initials = tempUserName[0].charAt(0);
        if (tempUserName.length > 1) {
            initials += tempUserName[tempUserName.length - 1].charAt(0);
        }
    }
    return initials.toUpperCase();
};

const CustomMessageRenderer: React.FC = () => {
    const { message } = useMessageContext();
    const [lastUserName, setLastUserName] = useState<string>('');
    const { channel } = useChannelStateContext(); // Get the current channel context

    // Effect to update the last user name
    useEffect(() => {
        if (message && message.user) {
            setLastUserName(message.user.name || 'Unknown User');
        }
    }, [message]);

    // Early return if message is undefined or blocked
    if (!message) {
        console.error('Message is undefined or blocked');
        return null;
    }

    // Format the message timestamp
    const formatDate = (date: Date): string => {
        return date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true });
    };

    let content: JSX.Element;

    var imageURL = (message.user?.photourl) as any;
    if (imageURL === null || imageURL === undefined || imageURL === "") {
        var name = getNameInitials(lastUserName);
        content = <h4>{name}</h4>
    }
    else {
        content = <Avatar image={imageURL} />;
    }


    return (
        <div className="custom-message left-aligned">

            {<div className={imageURL ? "" : "avatar-group channel_listing"}> {content}</div>}
            <div style={{width:'100%'}}>
                <div className="message-header">
                    <strong>{lastUserName}</strong>
                    <div className="message-time">
                        {formatDate(new Date(message.created_at!!))}
                    </div>
                </div> 
                <div className='message-chat-container'>
                    <MessageSimple message={message} />
                    {!message.deleted_at && (channel.type === "public_without_membership" || channel.type === "public_without_membership_watchlive") && <CustomReactionSelectors />}
                </div>

            </div>
        </div>
    );
};

export default CustomMessageRenderer;
