import React from "react";
import "../CustomPopupDialog/CustomPopupDialog.css";
type PopupDialogProps = {
  languageCode: any;
  onConfirm: (e: React.MouseEvent) => void;
  onCancel: (e: React.MouseEvent) => void;
};
const CustomPopupDialog: React.FC<PopupDialogProps> = ({languageCode, onConfirm, onCancel }) => {
  return (
    <div className="popup-dialog-overlay">
      <div className="popup-dialog">
        <p className="popup-dialog-message">{languageCode['You have blocked the participant. Do you want to unblock?']}</p>
        <div className="popup-dialog-actions">
          <button className="popup-dialog-button confirm" onClick={onConfirm}>
          {languageCode['Ok']}
          </button>
          <button className="popup-dialog-button cancel" onClick={onCancel}>
          {languageCode['Cancel']}
          </button>
        </div>
      </div>
    </div>
  );
};
export default CustomPopupDialog;