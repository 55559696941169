import { JSX } from "react/jsx-runtime";
import { DefaultSuggestionList } from "stream-chat-react";

export const SuggestionListWithHeader = (props) => {
  let header = "";
  let filteredValue = props.values || [];

  if (props.currentTrigger === "@") {
    header = "";
  }

  if (props.currentTrigger === "/") {
    header = "";
    filteredValue = []; 
  }

  if (props.currentTrigger === ":") {
    header = "";
  }

 
  
  const newProps = {
    ...props,
    values: filteredValue, // Override value prop if necessary
  };


  return (
    <>
      <div className="suggestion-list__header">{header}</div>
      <DefaultSuggestionList {...newProps} />
    </>
  );
};