import './MessagingChannelPreview.css';
import {
  ChannelPreviewUIComponentProps,
  ChatContextValue,
  DefaultStreamChatGenerics,
  useChatContext,
} from 'stream-chat-react';
import AvatarChannelView from '../AvatarChannelView/AvatarChannelView';

import { useEffect, useState } from 'react';
import type { MouseEventHandler } from 'react';
import type { Channel, ChannelMemberResponse, StreamChat } from 'stream-chat';
import { ChatPageType } from '../../enums/ChatPageType';
import ChatMessage from '../ChatMessage/ChatMessage';
import { useChannelContext } from '../ChannelContext/ChannelContext'; // Adjust the import path accordingly
import { useBlockUnblockContext } from '../../context/BlockUnblock';
import WebInterop from '../../implementation/WebInterop';
import Interop from "../../interfaces/Interop";
import { ChannelDataModel } from "../../models/ChannelDataModel";
import HybridWebView from '../../hybridWebView/HybridWebView';
import CustomPopupDialog from '../CustomPopupDialog/CustomPopupDialog';
import NativeInterop from '../../implementation/NativeInterop';


const getTimeStamp = (dateString: string) => {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  let half = 'AM';

  if (hours > 12) {
    hours -= 12;
    half = 'PM';
  }

  if (hours === 0) hours = 12; // Handle midnight case
  if (minutes.toString().length === 1) {
    minutes = `0${minutes}`; // Ensure two-digit minutes
  }

  return `${hours}:${minutes} ${half}`;
};

const formatMessageDate = (dateString: string) => {
  if (!dateString) return ''; // Check for undefined or empty string
  const messageDate = new Date(dateString);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (messageDate.toDateString() === today.toDateString()) {
    return getTimeStamp(dateString); // Use the existing getTimeStamp function for today's messages
  } else if (messageDate.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else {
    return messageDate.toLocaleDateString(); // Format it as a short date for older messages
  }
};

const getChannelName = (members: ChannelMemberResponse[]) => {
  const defaultName = 'Johnny Blaze';

  if (!members.length || members.length === 1) {
    return members[0]?.user?.name || members[0]?.user?.id;
  }
  return `${members[0]?.user?.name || members[0]?.user?.id}, ${members[1]?.user?.name || members[0]?.user?.id}`;
};

const getBlockedUsers = async (members: ChannelMemberResponse[], client: StreamChat<DefaultStreamChatGenerics>) => {
  try {
    const users = await client.getBlockedUsers();

    // Check if there are any blocked users
    if (users.blocks.length > 0) {
      // Check if the client has blocked the specific user
      const isUserBlocked = users.blocks.some((blockedUser) =>
        blockedUser.user_id === client.userID && blockedUser.blocked_user_id === members[0]?.user?.id
      );

      return isUserBlocked; // Return true if the user is blocked, else false
    } else {
      return false; // No blocks found
    }
  } catch (error) {
    console.error("Error while fetching blocked users:", error); // Log the error for debugging
    return false; // Return false in case of any error
  }
};


async function update(channel: any) {

  const response = await channel.updatePartial({ set: { frozen: false } });

  return response.channel;
}


type MessagingChannelPreviewProps = ChannelPreviewUIComponentProps & {
  channel: Channel;
  onClick: MouseEventHandler;
  setActiveChannel?: ChatContextValue['setActiveChannel'];
  languageCode: any,
};

const MessagingChannelPreview = (props: MessagingChannelPreviewProps) => {
  var { channel, setActiveChannel, onClick, latestMessage, languageCode } = props;
  const { channel: activeChannel, client } = useChatContext<DefaultStreamChatGenerics>();
  const [isTyping, setTyping] = useState<boolean>(false);
  const [isAlreadyMember, setAlreadyMember] = useState<boolean>(false);
  const [isblocked, setBlocked] = useState<boolean>(false);
  const [isMuted, setMuted] = useState<boolean>(false);
  const members = Object.values(channel.state.members).filter(
    ({ user }) => user?.id !== client.userID,
  );
  const { selectedChannel, setSelectedChannel } = useChannelContext(); // Get context
  const { isUserBlocked, toggleBlock } = useBlockUnblockContext(); // Use context to get blocked status
  const [blockedChannels, setBlockedChannels] = useState<{ [key: string]: boolean }>({});
  const [showPopup, setShowPopup] = useState<boolean>(false);  // State to control popup visibility
  const [isUserBlock, setIsUserBlocked] = useState<boolean>(false);
  let interop: Interop;


  // useEffect for updating the block state based on user block status
  useEffect(() => {
    if (channel.type === 'messaging') {
      const userId = members[0]?.user?.id;
      if (userId) {
          let blockedState=true;
          if (setActiveChannel) {
            blockedState = channel.data?.frozen!!;
          }
          else {
            blockedState = channel.data?.frozen || isUserBlocked(userId);
          }
          setBlockedChannels(prevState => ({
            ...prevState,
            [channel.id!!]: blockedState, // Ensure blocked state is updated correctly per channel
          }));

      }
    }
  }, [isUserBlocked]);

  useEffect(() => {
      setBlocked(blockedChannels[channel.id!!] ?? false); // Ensure the state is updated whenever blockedChannels changes
    if(isblocked){
      getBlockedUsers(members, client).then(blockedUser=>  {
        if(blockedUser) {
          setIsUserBlocked(false);
        }
        else{
          setIsUserBlocked(true);
        }
        });
    }
  }, [blockedChannels, channel.id]);


  useEffect(() => {

    if (HybridWebView.IsWebView()) {
      interop = new NativeInterop();
    }
    else {
      interop = new WebInterop();
    }
    channel!.on('all', (event: any) => {
      if (event.type === 'all') {
        let value = false;
        if (event.text === "user blocked") {
          if (event.user.id === members[0].user_id!!) {
            setIsUserBlocked(true);
          }
          value = true
        }
        setBlocked(value);
      }
    });


    channel.on((event: any) => {
      if (channel.type == "public_with_membership") {

        if (event.type === "member.updated") {

          var eventData = (event.member) as any;
          if (eventData != null && eventData != undefined && eventData.user_id != undefined) {
            setAlreadyMember(true);
          }
        }
        if (channel.muteStatus().muted) {
          var data = channel.state.read[client.userID!!]
          if (data.unread_messages > 0) {
            setMuted(true)
          }
        }

      }
      if (event.type === "message.new") {
      // console.log("In message new");

        if (event.user?.id != client?.user?.id) {
          if (event.channel_type !== "public_without_membership") {

            const details = channel.data?.details || '';  // Default to empty string
            let EntityId = '';

            if (details !== "") {
              try {
                const parsedObject = JSON.parse(details as string);
                EntityId = parsedObject.EntityId || '';  // Default to empty string if not present
              } catch (error) {
                console.error("Failed to parse details:", error);
                EntityId = '';  // If parsing fails, set EntityId to empty string
              }
            }

            const channelData = new ChannelDataModel(
              event?.channel_last_message_at,
              event.channel_type,
              false,
              event.cid,
              EntityId.toString() || ''  // Ensure EntityId is a string
            );
            interop.SendNewMessageUpdate(channelData);

          }
        }
      }
    });

    if (channel.type == "public_with_membership") {
      const alreadyChannelMembers = Object.values(channel.state.members || {}).filter(
        (member) => member.user?.id === client?.user?.id,
      );
      if (alreadyChannelMembers.length > 0) {
        setAlreadyMember(true);
      } else {
        setAlreadyMember(false);
      };
    }

    else if (channel.type == "messaging") {
      setAlreadyMember(true);
      if (channel.data?.frozen === true) {
        setBlocked(true)
        toggleBlock(members[0]?.user?.id!);
      }
    }

    else if (channel.type == "public_without_membership" || channel.type == "public_without_membership_watchlive") {
      setAlreadyMember(true);

    }
  }, []);



  let unreadCount = 0;

  var data = channel.state.read[client.userID!!]
  if (data.unread_messages > 0) {
    unreadCount = data.unread_messages
  }


  channel.on('typing.start', event => {
    if (event.user?.id != client?.user?.id)
      setTyping(true)
  })

  channel.on('typing.stop', event => {
    if (event.user?.id != client?.user?.id)
      setTyping(false)
  })

  const handleUnblockConfirmation = async (e: React.MouseEvent) => {
    e.stopPropagation();  // Prevent the click event from bubbling up
    const userId = members[0]?.user?.id;
    if (!userId) return;
    // If the user is currently blocked
    const isCurrentlyBlocked = blockedChannels[channel.id!!] || channel.data?.frozen;
    if (isCurrentlyBlocked) {
      // Unblock the user and update channel state
      client.unBlockUser(userId).then(() => {
        // Triggering an update to unfreeze the channel
        update(channel).then(channeldata => {
          const blockedState = isUserBlocked(userId);

          setBlockedChannels(prevState => ({
            ...prevState,
            [channel.id!!]: false,
          }));

          if (setActiveChannel) {
            toggleBlock(members[0]?.user?.id!);
          }
          setBlocked(false);
          setShowPopup(false); // Close the popup after confirmation
          channel = channeldata;
        })
      });
      await channel.sendEvent({ type: 'all', text: 'user unblocked' });
    }
  };
  // Handle cancellation (Cancel button click)
  const handlePopupClose = (e: React.MouseEvent) => {
    e.stopPropagation();  // Prevent the click event from bubbling up
    setShowPopup(false); // Close the popup without doing anything
  };
  // Determine if this channel is the active one
  const isActive = activeChannel?.id === channel.id;
  return (
    <div
      className={`channel-preview__container ${isActive ? 'active' : 'inactive'}`}
      onClick={(e) => {
        e.preventDefault();
        if (!isblocked) {
          onClick(e);
          setActiveChannel?.(channel);
          setSelectedChannel(channel); // Set the selected channel in context
        } else {
          getBlockedUsers(members, client).then(blockedUser => {
            if (blockedUser)
              setShowPopup(true);
          });
        }
      }}
    >
      <AvatarChannelView data={channel} pageType={ChatPageType.ChannelListing} />

      <div className='channel-preview__content-wrapper'>
        <div className='channel-preview__content-top'>
          <p className='channel-preview__content-name'>
            {channel.data?.name || getChannelName(members)}
          </p>
          <p className='channel-preview__content-time'>{formatMessageDate(channel.state.last_message_at?.toString()!!)}</p>
        </div>
        <div className={
          isAlreadyMember
            ? (unreadCount || isTyping || isMuted)
              ? 'channel-preview__content-message-unread'
              : 'channel-preview__content-message'
            : 'channel-preview__content-message'
        }>
          {
            <ChatMessage isBlocked={isblocked} isAlreadyMember={isAlreadyMember} isTyping={isTyping}
            latestMessage={latestMessage} languageCode={languageCode} isUserBlock={isUserBlock} />
          }

        </div>
      </div>
      {showPopup && (
        <CustomPopupDialog
          languageCode={languageCode}
          onConfirm={(e) => handleUnblockConfirmation(e)}
          onCancel={(e) => handlePopupClose(e)}
        />
      )}
    </div>
  );
};

export default MessagingChannelPreview;

