import React, { useEffect, useRef, useState } from 'react';
import { DefaultStreamChatGenerics, useChannelStateContext, useChatContext } from 'stream-chat-react';
import './MessagingChannelHeader.css';
import { BlockUserIcon, UnBlockUserIcon, ChevronLeft, UnmuteChannelIcon, MuteChannelIcon } from '../../assets';
import { AvatarChannelView } from '../';

import type { StreamChatGenerics } from '../../types';
import { ChatPageType } from '../../enums/ChatPageType';
import { useBlockUnblockContext } from '../../context/BlockUnblock';
import { useAlertContext } from '../../context/AlertContext';  // Import the custom hook
import { useUserConnection } from '../../hooks/useUserConnection';
import Interop from '../../interfaces/Interop';
import { ClientCredential } from '../../models/ClientCredential';
import { StreamChat } from 'stream-chat';

type Props = {
  theme: string;
  toggleMobile: () => void;
  isBlocked: boolean;
  isMuted: boolean;
  interop: Interop;
  isBlockIconVisible: boolean;
  languageCode: any;
  platformType: string;
  UpdateBlockUnblockState: (value: boolean) => void;
  UpdateMutedUnMutedState: (value: boolean) => void;

};


const MessagingChannelHeader = (props: Props) => {
  const { theme, platformType, interop, toggleMobile, UpdateBlockUnblockState, UpdateMutedUnMutedState, isBlockIconVisible, languageCode } = props;
  const { channel } = useChannelStateContext();
  const [channelName, setChannelName] = useState(channel.data?.name || '');
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  var isBlocked = props.isBlocked;
  var isMuted = props.isMuted;

  // const [showAlert, setShowAlert] = useState(false);  // State to show the custom alert
  // const [alertMessage, setAlertMessage] = useState('');  // State for the alert message

  const { toggleBlock } = useBlockUnblockContext(); // Get context
  const { setShowAlert, setAlertMessage } = useAlertContext();
  const apiKey = process.env.REACT_APP_STREAM_KEY || "";
  const [refreshToken, setRefreshToken] = useState<ClientCredential>();
  
  
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const { client, setActiveChannel } = useChatContext<DefaultStreamChatGenerics>();


  const members = Object.values(channel.state.members || {}).filter(
    (member) => member.user?.id !== client?.user?.id,
  );

  const PerformBackNavigation = () => {
    toggleMobile()
    setActiveChannel(undefined);
  }

  const blockUnblockUser = async (blockedUserId: string) => {
    if (isBlocked) {
      try {
        await client?.unBlockUser(blockedUserId, client?.user?.id).then(
          _ => {
            UpdateBlockUnblockState(false)
          }
        );

        await channel.updatePartial({ set: { frozen: false } });
        isBlocked = false;
        await channel.updatePartial({ set: { frozen: false } });


        toggleBlock(blockedUserId);
        await channel.sendEvent({ type: 'all', text: 'user unblocked' });
        setAlertMessage(languageCode['User unblocked!']);
        setShowAlert(true);  // Show the alert
      } catch (err: any) {
        if (err.code == 40) {
          try {
            await interop.GetCredentials().then(async credentialData=>{
              setRefreshToken(credentialData);
            });
          } catch (refreshError) {
            console.error('Error during refresh token process:', refreshError);
          }

        }
      }
    } else {
      try {
        await client?.blockUser(blockedUserId, client?.user?.id).then(_=> {
          UpdateBlockUnblockState(true)
        });
        await channel.updatePartial({ set: { frozen: true } });
        toggleBlock(blockedUserId);
        await channel.sendEvent({ type: 'all', text: 'user blocked' });
        isBlocked = true;
        setAlertMessage(languageCode['User blocked!']);
        setShowAlert(true);  // Show the alert
      } catch (err: any) {
        if (err.code == 40) {
          try {
            await interop.GetCredentials().then(async credentialData=>{
              setRefreshToken(credentialData);
            });
          } catch (refreshError) {
            console.error('Error during refresh token process:', refreshError);
          }
        }
      }
    }
  }

  const muteUnmuteChannel = () => {

    if (isMuted) {
      channel.unmute({ user_id: client?.user?.id })
        .then(_ => UpdateMutedUnMutedState(false)).catch(async (e: any) => {
          if (e.code == 40) {
            try {
              await interop.GetCredentials().then(async credentialData=>{
                setRefreshToken(credentialData);
              });
            } catch (refreshError) {
              console.error('Error during refresh token process:', refreshError);
            }
          }
        });
      setAlertMessage(languageCode['Channel unmuted!']);
      setShowAlert(true);  // Show the alert
      isMuted = false;

    } else {
      channel.mute({ user_id: client?.user?.id })
        .then(_ => UpdateMutedUnMutedState(true)).catch(async (e: any) => {
          if (e.code == 40) {
            try {
              await interop.GetCredentials().then(async credentialData=>{
                setRefreshToken(credentialData);
              });
            } catch (refreshError) {
              console.error('Error during refresh token process:', refreshError);
            }
          }
        });
      setAlertMessage(languageCode['Channel muted!']);
      setShowAlert(true);  // Show the alert
      isMuted = true;

    }

  }
  const updateChannel = async () => {
    if (channelName && channelName !== channel.data?.name) {
      await channel.update(
        { name: channelName },
        { text: `Channel name changed to ${channelName}` },
      );
    }

    setIsEditing(false);
  };
  useEffect(() => {
   // setChatClient(client)
    setActiveChannel(channel)
  }, []);

  useEffect(() => {
    if (refreshToken) {

    //  console.log(refreshToken);
     
      const client = new StreamChat<DefaultStreamChatGenerics>(apiKey, {
        enableInsights: true,
        enableWSFallback: true,
      });

      const connectUser = async () => {
        await client?.connectUser({ id: refreshToken.UserId, eventId: refreshToken.EventId }, refreshToken.UserToken)
          .then((data) => {
            setChatClient(client);

            const channeldata = client.channel(channel!.type,channel.id);
            // Get the channel and set it in state
            setActiveChannel(channeldata);
       //     console.log('Token refreshed!' + data);
          })
          .catch((error: any) => {
        //    console.log('Failed to refresh token',error);
          });
      };
      connectUser();

    }
  }, [refreshToken]);



  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef?.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (!channelName) {
      setTitle(
        members.map((member) => member.user?.name || member.user?.id || 'Unnamed User').join(', '),
      );
    }
  }, [channelName, members]);

  const EditHeader = () => (
    <form
      style={{ flex: 1 }}
      onSubmit={(event) => {
        event.preventDefault();
        inputRef?.current?.blur();
      }}
    >
      <input
        autoFocus
        className='channel-header__edit-input'
        onBlur={updateChannel}
        onChange={(event) => setChannelName(event.target.value)}
        placeholder='Type a new name for the chat'
        ref={inputRef}
        value={channelName}
      />
    </form>
  );

  (window as any).PerformBackAction = () => {
    PerformBackNavigation();
  };


  const getChannelIcon = (channel:any, isBlockIconVisible:boolean, isBlocked:boolean, isMuted:boolean) => {
    if (channel.type === "messaging") {
      if (isBlockIconVisible) return undefined;
      return isBlocked ? <UnBlockUserIcon /> : <BlockUserIcon />;
    }
  
    if (channel.type === "public_without_membership" || channel.type === "public_without_membership_watchlive") {
      return undefined;
    }
  
    return isMuted ? <UnmuteChannelIcon /> : <MuteChannelIcon />;
  };


  return (
    <div className='messaging__channel-header'>
      <div role="button" aria-label="Back button" id='mobile-nav-icon' className={`${theme}`} style={{ display: platformType == 'BlazorPWA' ? 'none' : 'block' }}
        onClick={PerformBackNavigation}>
        <ChevronLeft />
      </div>

      <AvatarChannelView data={channel} pageType={ChatPageType.ChannelDetail} />
      <div className='channel-header__name'>{channelName || title}</div>
      { }

      <div className='messaging__channel-header__right'
        onClick={() => {
          if (channel.type != "messaging") {
            muteUnmuteChannel();
          } else {
            blockUnblockUser(members[0]?.user!.id);
          }

        }}>

        {
           getChannelIcon(channel, isBlockIconVisible, isBlocked, isMuted)
        }

      </div>
      {/* Show the custom alert if needed */}
      {/* {!isEditing ? (
        <div className='channel-header__name'>{channelName || title}</div>
      ) : (
        <EditHeader />
      )}
      <div className='messaging__channel-header__right'>
        <TypingIndicator />
        {channelName !== 'Social Demo' &&
          (!isEditing ? <ChannelInfoIcon {...{ isEditing, setIsEditing }} /> : <ChannelSaveIcon />)}
      </div> */}
    </div>
  );
};

export default React.memo(MessagingChannelHeader);
